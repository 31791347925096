.side-line {
    height: 8px;
    width: 8px;
    margin: 0 10px;
    background-color: #c679e2;
    transform: rotate(45deg);
}

.middle-line {
    width: 180px;
    height: 4px;
    background-color: #c679e2;
    border-radius: 4px;
    position: relative;
}