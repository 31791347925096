.links{
    text-decoration: none;
    color: black;
}
.links:hover, 
.links:focus, 
.links:active{
    color: blueviolet;
    font-weight: bold;
}
.footer{
    background: url(../../../assets/images/footer-bg.png) top no-repeat;
    background-size: cover;
}