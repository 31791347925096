.primary-button {
    color: black;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    text-align: center;
    width: 190px;
    height: 49px;
    background-color: #ddcbff;
    transition-duration: 0.5s;
    border-radius: 10px;
}

.primary-button:hover {
    background-color: #9e79e2;
    border-color: #C679E2;
    color: white;
    border-width: 2px;
}